import React from "react";
import Layout from "../Layout";
import "./style.scss";

export default function RefundPolicy() {
  return (
    <Layout>
      <div className="privacypolicy">
        <div className="m_heading">
          <h1>Refund and Cancellation Policy</h1>
        </div>

        <div className="ppolicey_text">
          <p>
            Black Elephant Digital does not provide a refund for the studio
            bookings made. Bookings for the times outlined are final and cannot
            be cancelled. A no-show charge of 100% of the booking amount applied
            for any bookings that go unutilised. Any rescheduled studio hours
            are at the discretion of the management, with atleast 24 hour prior
            notice.<br></br>
            <br></br> Please call <strong>+91-8130047247</strong> or email{" "}
            <strong>studios@blackelephantdigital.com</strong> for any issues.
          </p>
        </div>
      </div>
    </Layout>
  );
}
